// src/components/common/MenuLinks.tsx

import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

interface LinkItem {
  label: string;
  path: string;
  matchPath?: (pathname: string) => boolean;
}

interface MenuLinksProps {
  links: LinkItem[];
}

const MenuLinks: React.FC<MenuLinksProps> = ({ links }) => {
  const location = useLocation();

  return (
    <div className="flex flex-col sm:flex-row justify-center items-center w-full py-8 sm:py-16 space-y-4 sm:space-y-0 sm:space-x-8">
      {links.map((link, index) => {
        const isActive = link.matchPath
          ? link.matchPath(location.pathname)
          : location.pathname === link.path;

        return (
          <NavLink
            key={index}
            to={link.path}
            className={`font-paperboard text-2xl sm:text-4xl px-6 py-3 rounded-md transition-colors duration-300 ${
              isActive
                ? 'bg-holdrens-gold text-black'
                : 'bg-holdrens-red text-white hover:bg-holdrens-gold hover:text-black'
            }`}
          >
            {link.label}
          </NavLink>
        );
      })}
    </div>
  );
};

export default MenuLinks;